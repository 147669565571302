import AutoPlayCases from "../components/molecules/AutoPlayCases/AutoPlayCases";
import React, { useState } from "react";
import BrightAutoPlay from "../components/molecules/BrightAutoPlay/BrightAutoPlay";
import * as styles from "./styles.module.scss";
import LibraryCases from "../components/ogranisms/LibraryCases/LibraryCases";
import UserSlider from "../components/ogranisms/UserSlider/UserSlider";
function UserCases() {
  const [state, setState] = useState(false);

  const firstVideoSlider = [
    {
      url: "https://cdn.masterwizr.com/presentations/Screenshot 2023-10-03 at 14.37.13.png-1696336642679.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/SustainableSolutions_Final_VO%20-%20Valmir.mp4-1695993372692.mp4",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Screenshot 2023-10-03 at 14.35.24.png-1696336534357.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/Information%20Technology%20-%20Explainer%20Video%20%20Final%20V2%20-%20Sound%202%20-%20Valon.mp4-1695991071957.mp4",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Screenshot 2023-10-03 at 14.38.10.png-1696336698984.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/Investors_Final_VO%20-%20Valmir.mp4-1695993022855.mp4",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 41592 (1).png-1696712120748.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/MW%20Media%20-%20Explainer%20Video%20FInal%20V2%20Valon.mp4-1696514590180.mp4",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Screenshot 2023-10-03 at 14.40.30.png-1696336839773.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/Campaigns%20-%20Explainer%20Video%20Final%20V4%20-%20Valon.mp4-1695993734953.mp4",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Screenshot 2023-10-03 at 14.39.51.png-1696336809669.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/Sports_Final_VO%20-%20Valmir.mp4-1695993603855.mp4",
    },
  ];
  const videosData = [
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 41514 (2).png-1698059979013.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/Thesis%20-%20Explainer%20Video%20Final%20V3%20-%20Valon.mp4-1695990723192.mp4",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Screenshot 2023-10-03 at 14.32.10.png-1696336348331.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/Management_Final_VO%20-%20Valmir.mp4-1695992487796.mp4",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Screenshot 2023-10-03 at 14.34.45.png-1696336497982.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/HR_Final_VO%20-%20Valmir.mp4-1695998583540.mp4",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Screenshot 2023-10-03 at 14.35.59.png-1696336569468.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/Sales%20-%20Explainer%20Video%20-%20Final%20V4%20-%20Valon.mp4-1695992134330.mp4",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Screenshot 2023-10-03 at 14.36.37.png-1696336606883.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/Constulting%20-%20Explainer%20Video%20Final%20V2%20Valon.mp4-1695991167132.mp4",
    },
    {
      url: "https://cdn.masterwizr.com/showroompresentation-JUo2mM/agencies.png-1696480665338.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/PR%20Agencies%20-%20Explainer%20Video%20Final%20V3%20-%20Valon.mp4-1695991377871.mp4",
    },
  ];

  const secondVideoData = [
    {
      url: "https://cdn.masterwizr.com/presentations/Screenshot 2023-10-05 at 15.52.40.png-1696513969490.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/Job%20Interview%20-%20Explainer%20Video%20Final%20V4%20%20Valon.mp4-1697228385919.mp4",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Screenshot 2023-10-07 at 22.22.38.png-1696710167170.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/Events_Final_VO%20-%20Valmir.mp4-1695990887166.mp4",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 41593 (1).png-1696711721635.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/AboutMe_Final_VO%20-%20Valmir.mp4-1696710527049.mp4",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 41594 (9).png-1696711850574.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/Branding_Final_VO%20-%20Valmir.mp4-1696710801747.mp4",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 41595 (2).png-1696711976211.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/BusinessPlan_Final_VO%20-%20Valmir.mp4-1696710979974.mp4",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 41509 (4).png-1696712069570.png",
      videoUrl:
        "https://cdn.masterwizr.com/presentations/Training%20-%20Explainer%20Video%20Final%20V3%20-%20Valon.mp4-1695993187587.mp4",
    },
  ];

  const data = [
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 143.png-1693405370904.png",
      videoUrl:
        "https://library.masterwizr.com/sharing/shared_660f21e3870984a50b04ce9b-open/folderview",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 135.png-1693405644313.png",
      videoUrl:
        "https://library.masterwizr.com/sharing/shared_660f2290870984a50b04ceac-open/folderview",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 161.png-1693404939392.png",
      videoUrl:
        "https://library.masterwizr.com/sharing/shared_660f22b3870984a50b04ceb2-open/folderview",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 31.png-1693405725431.png",
      videoUrl:
        "https://library.masterwizr.com/sharing/shared_660f22d0870984a50b04ceb8-open/folderview",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 21.png-1693408327005.png",
      videoUrl:
        "https://library.masterwizr.com/sharing/shared_660f22f3870984a50b04cebe-open/folderview",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 59.png-1693407646589.png",
      videoUrl:
        "https://library.masterwizr.com/sharing/shared_660f2311870984a50b04cec4-open/folderview",
    },
  ];
  const librarydata = [
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 139 (1).png-1695762073957.png",
      videoUrl:
        "https://library.masterwizr.com/sharing/shared_660f237e870984a50b04ceca-open/folderview",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 111.png-1695762713917.png",
      videoUrl:
        "https://library.masterwizr.com/sharing/shared_660f23a2870984a50b04ced0-open/folderview",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 125.png-1693405567045.png",
      videoUrl:
        "https://library.masterwizr.com/sharing/shared_660f23be870984a50b04ced6-open/folderview",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 33.png-1693408114560.png",
      videoUrl:
        "https://library.masterwizr.com/sharing/shared_660f23ce870984a50b04cedc-open/folderview",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 1.png-1693407810997.png",
      videoUrl:
        "https://library.masterwizr.com/sharing/shared_660f2406870984a50b04cee2-open/folderview",
    },
    {
      url: "https://cdn.masterwizr.com/presentations/Frame 1.png-1693407810997.png",
      videoUrl:
        "https://library.masterwizr.com/sharing/shared_660f241f870984a50b04ceee-open/folderview",
    },
  ];

  const firstSlider = [
    {
      url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1712268568/Templates/Screenshot_2024-04-05_at_12.09.22_AM_jk6pur.png",
      videoUrl:
        "https://publisher.masterwizr.com/share/43de1def-fdf9-4b7d-8877-09dd62333067",
    },
    {
      url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1712268658/Templates/Screenshot_2024-04-05_at_12.10.53_AM_velbz9.png",
      videoUrl:
        "https://publisher.masterwizr.com/share/0e11d2e2-a231-4ffc-9617-c801987b3410",
    },
    {
      url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1712274423/Templates/Screenshot_2024-04-05_at_1.46.57_AM_ihoyk7.png",
      videoUrl:
        "https://publisher.masterwizr.com/share/bdbc1ecd-d876-4754-ae99-5f609ab8b4fa",
    },
    {
      url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1712269330/Templates/Screenshot_2024-04-05_at_12.22.02_AM_othoug.png",
      videoUrl:
        "https://publisher.masterwizr.com/share/c6aec335-e1fa-4b18-8a96-ea34f37db22a",
    },
    {
      url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1712269168/Templates/Screenshot_2024-04-05_at_12.19.22_AM_nzgspu.png",
      videoUrl:
        "https://publisher.masterwizr.com/share/883f2a78-9029-47f7-963b-864a792a3a90",
    },
    {
      url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1712269506/Templates/Screenshot_2024-04-05_at_12.24.59_AM_s94wjw.png",
      videoUrl:
        "https://publisher.masterwizr.com/share/895fed25-b43d-424d-8fa3-e0670e6d6637",
    },
  ];
  return (
    <>
      <div className={styles.casesContainer}>
        <div className={styles.casesTabs}>
          <button
            className={!state && styles.activeTab}
            onClick={() => setState(false)}
          >
            User cases
          </button>
          <button
            className={state && `${styles.activeTab} ${styles.explainerActive}`}
            onClick={() => setState(true)}
          >
            Explainer videos
          </button>
        </div>
        {state ? (
          <div>
            <AutoPlayCases
              backgroundImage="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1712516974/Frame_1261153152_ulzjjy.jpg"
              title="Dive Into Insightful Explanations"
              subtitle="Watch Our Explainer Videos and Explore Further"
              data={firstVideoSlider}
              video={true}
            />
            <BrightAutoPlay
              title="Watch, relate, create."
              subtitle="MW - All in One place"
              data={secondVideoData}
              video={true}
            />
            <h1 className={styles.usercaseTitle}>
              Try it yourself <br />
              <span style={{ color: "#ec7600" }}>
                Ideas worth applying
              </span>{" "}
            </h1>
            <LibraryCases
              title="Read, listen, discover."
              subtitle="What connects with you?"
              data={videosData}
            />
          </div>
        ) : (
          <div>
            <AutoPlayCases
              mpublisher
              backgroundImage="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1712516879/Group_1261153187_lwkz6l.jpg"
              subtitle="Insights from Our Unique Use Cases"
              title="Unlocking the Extraordinary"
              extraClass="useCasesSlider"
              data={firstSlider}
            />
            <BrightAutoPlay
              title="Watch, relate, create."
              subtitle="MW - All in One place"
              data={data}
            />
            <h1 className={styles.usercaseTitle}>
              Try it yourself <br />
              <span style={{ color: "#ec7600" }}> Ideas worth applying </span>
            </h1>
            <LibraryCases
              title="Read, listen, discover."
              subtitle="All in One place"
              data={librarydata}
            />
            <UserSlider />
          </div>
        )}
      </div>
    </>
  );
}
export default UserCases;
